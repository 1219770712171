<template>
  <div>
    <img src="../../../public/images/qt/敬请期待@3x.jpg" width="100%" height="100%">
  </div>
</template>

<script>
export default {
  name: "低功耗T31ZL+Hi3861"
}
</script>

<style scoped>

</style>